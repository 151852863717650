import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CheckboxOption } from "components/forms/checkbox/checkbox";

import { RootState } from 'state';

import { FilterTypes, ClientFilterTypes, QuickFilterTypes } from "types/inbox";

type AllFilter = {
  filterType: FilterTypes | ClientFilterTypes;
  appliedFilters: CheckboxOption[];
};

interface InboxFiltersSlice {
  quickFilters: QuickFilterTypes[];
  allFilters: AllFilter[];
}

const initialState: InboxFiltersSlice = {
  quickFilters: [],
  allFilters: [],
};

export const inboxFiltersSlice = createSlice({
  name: "inboxFilters",
  initialState,
  reducers: {
    setQuickFilters: (state, action: PayloadAction<QuickFilterTypes[]>) => {
      state.quickFilters = action.payload;
    },
    setAllFilters: (state, action: PayloadAction<AllFilter[]>) => {
      state.allFilters = action.payload;
    },
  },
});

export const currentQuickFiltersSelector = (state: RootState) =>
  state.inboxFilters.quickFilters;

export const currentAllFiltersSelector = (state: RootState) =>
  state.inboxFilters.allFilters;

export const { setQuickFilters, setAllFilters } = inboxFiltersSlice.actions;

export default inboxFiltersSlice.reducer;
export function useQuickFilters(): QuickFilterTypes[] {
  const filters = useSelector(currentQuickFiltersSelector);
  return useMemo(() => filters, [filters]);
}

export function useAllFilters(): AllFilter[] {
  const filters = useSelector(currentAllFiltersSelector);
  return useMemo(() => filters, [filters]);
}

export type { InboxFiltersSlice };
