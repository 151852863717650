import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";

import { env } from "env";

type AuthenticationToken = string;

interface AuthenticationStrategy {
  name: string;
  authenticate(): Promise<AuthenticationToken>;
}

class EmailAndPasswordAuthenticationStrategy implements AuthenticationStrategy {
  public name = "email_and_password";

  public email: string;
  public password?: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

  async authenticate(): Promise<AuthenticationToken> {
    let response: AxiosResponse;
    try {
      response = await axios.post(`${env.REACT_APP_HARBOR_API_BASE_URL}/api/authenticate`, {
        username: this.email,
        password: this.password,
      });

      if (response.status !== 200) {
        throw "to_outside_catch_to_wrap_in_our_error_class"; // eslint-disable-line no-throw-literal
      }

      delete this.password;

      return response.data.id_token as AuthenticationToken;
    } catch (err) {
      const error = err as AxiosError;
      response = error.response || response!;
      throw new Error(error.response?.data);
    }
  }
}

class TokenAuthenticationStrategy implements AuthenticationStrategy {
  public name = "token";

  public token: string;

  constructor(token: string) {
    this.token = token;
  }

  async authenticate(): Promise<AuthenticationToken> {
    return this.token;
  }
}

class Connection {
  private readonly __axiosInstance__: AxiosInstance;
  public isConnected = false;

  constructor(axiosInstance: AxiosInstance) {
    this.__axiosInstance__ = axiosInstance;
  }

  async connect() {
    this.isConnected = true;
  }

  get axiosInstance() {
    if (!this.isConnected) {
      throw new Error("tried to create axios instance before being connected");
    }

    return this.__axiosInstance__;
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, config);
  }

  post<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(url, data, config);
  }

  put<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(url, data, config);
  }

  patch<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.patch<T>(url, data, config);
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(url, config);
  }
}

export default Connection;
export { EmailAndPasswordAuthenticationStrategy };
export { TokenAuthenticationStrategy };
export type { AuthenticationStrategy };
