import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from 'state';
import Touchpoint, { TouchpointAttributes } from "models/touchpoint";
import { TouchpointVersionAttributes } from "models/touchpoint-version";

import { emptyPaginatedResponse, PaginatedResponse } from "types/pagination";

interface TouchpointsSlice {
  current: TouchpointAttributes | null;
  versions: PaginatedResponse<TouchpointVersionAttributes>;
}

const initialState: TouchpointsSlice = {
  current: null,
  versions: emptyPaginatedResponse,
};

export const touchpointsSlice = createSlice({
  name: "touchpoints",
  initialState,
  reducers: {
    setCurrentTouchpoint: (state, action: PayloadAction<TouchpointAttributes | null>) => {
      state.current = action.payload;
    },
    setTouchpointVersions: (
      state,
      action: PayloadAction<PaginatedResponse<TouchpointVersionAttributes>>,
    ) => {
      state.versions = action.payload;
    },
  },
});

export const currentTouchpointSelector = (state: RootState) =>
  state.touchpoints.current;

export const touchpointVersionsSelector = (state: RootState) =>
  state.touchpoints.versions;

export const { setCurrentTouchpoint, setTouchpointVersions } =
  touchpointsSlice.actions;
export default touchpointsSlice.reducer;

export function useCurrentTouchpoint(): Touchpoint {
  const attributes = useSelector(currentTouchpointSelector);
  if (!attributes) {
    throw new Error("useCurrentTouchpoint: called before a touchpoint was stored in redux");
  }

  return useMemo(() => {
    return new Touchpoint(attributes);
  }, [attributes]);
}

export type { TouchpointsSlice };
