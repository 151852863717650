import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from 'state';
import User, { UserAttributes } from "models/user";

interface UsersSlice {
  current: UserAttributes | null;
}

const initialState: UsersSlice = { current: null };

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserAttributes | null>) => {
      state.current = action.payload;
    },
  },
});

export const currentUserSelector = (state: RootState) => state.users.current;

export const { setCurrentUser } = usersSlice.actions;
export default usersSlice.reducer;

export function useCurrentUser(): User {
  const attributes = useSelector(currentUserSelector);

  if (!attributes) {
    throw new Error("useCurrentUser: called before a user was stored in redux");
  }

  return useMemo(() => new User(attributes), [attributes]);
}

export type { UsersSlice };
