import { useHistory } from "react-router-dom";

import PageContent from "components/partials/page-content/page-content";
import { CampaignForm } from "containers/admin/clients/campaign/components/campaign-form/campaign-form";
import ClientSecondaryNav from "containers/admin/clients/client/components/client-secondary-nav/client-secondary-nav";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks/clients";
import Campaign, { CampaignAttributes } from "models/campaign";
import { Unpersisted } from "models/model";

import { getErrorMessage } from "utilities";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { ChildPageProps } from "types";

const CampaignAddPage = ({ parentUrl }: ChildPageProps) => {
  const history = useHistory();
  const client = useCurrentClient();

  usePagePermission(Permission.PERM_CAMPAIGN_WRITE);

  const submitNewCampaign = (attributes: Unpersisted<CampaignAttributes>) => {
    Campaign.create(client.id, attributes)
      .then((campaign) => {
        toast.success({
          data: {
            title: "Campaign created",
            message: "Manage additional campaign settings from the campaign card",
          },
        });
        history.push({
          pathname: `/clients/${client.id}/campaigns/${campaign.id}`,
        });
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create Campaign",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const cancelCreateCampaign = () => {
    // Redirect to campaigns page
    history.push(`/clients/${client.id}/campaigns`);
  };

  return (
    <>
      <ClientSecondaryNav parentUrl={parentUrl} />
      <PageContent>
        <CampaignForm onSubmit={submitNewCampaign} onCancel={cancelCreateCampaign} />
      </PageContent>
    </>
  );
};

export default CampaignAddPage;
