export enum TouchpointType {
  EMAIL = "EMAIL",
  LANDING_PAGE = "LANDING_PAGE",
  THEME = "THEME",
}

export enum TouchpointTypeLabel {
  EMAIL = "Email",
  EMAILS = "Emails",
  LP_OTHER = "Other", // Formerly "Landing Page" or "Landing Pages"
  LP_TOUCHPOINT = "Touchpoint", // Formerly "Landing Page"
  LP_TOUCHPOINTS = "Touchpoints", // Formerly "Landing Pages"
}

export enum TouchpointStatus {
  DESIGN = "Design",
  DEVELOPMENT = "Development",
  DONE = "Done",
  NEW = "New",
  QA = "QA",
}

// TODO: Remove the "" option, but fix functions that require it.
export type LpRedirectionType = "TOUCHPOINT" | "URL" | "";

export interface TouchpointPreviewProps {
  emptyMessage?: string;
  htmlContent: any;
  htmlJSON: string;
  isCommentMode?: boolean;
  mobileView?: boolean;
}
