import React from "react";
import { useHistory } from "react-router-dom";

import RouterLink from "components/partials/router-link/router-link";
import { TableViewButton } from "components/table/table-icon-button/table-icon-button";

import Campaign from "models/campaign";

import { useGetProgramTypesQuery, getDictionaryItemDescription } from "state/api/dictionary";
import { useCurrentClient } from "state/ducks/clients";

import { Route } from "utilities/app-routes";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";
import { LastUpdatedColumn } from "components/table/table-columns/table-columns";

interface CampaignRowProps {
  campaign: Campaign;
}

const CampaignRow: React.FC<CampaignRowProps> = ({ campaign }) => {
  const history = useHistory();
  const client = useCurrentClient();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  const { clients, campaigns, creative } = Route;
  const campaignCreativeRoute = `${clients}/${client.id + campaigns}/${campaign.id}${creative}`;

  return (
    <TableRow>
      <TableCell>
        <RouterLink data-testid={`campaign-table-link-${campaign.id}`} to={campaignCreativeRoute}>
          {campaign.name}
        </RouterLink>
      </TableCell>
      <TableCell>{getDictionaryItemDescription(programTypes, campaign.programType)}</TableCell>
      <LastUpdatedColumn lastModifiedDate={campaign.lastModifiedDate} />
      <TableCell>
        <div className="flex">
          <TableViewButton
            title="View campaign"
            aria-label="View campaign"
            onClick={() => history.push(campaignCreativeRoute)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CampaignRow;
