import transform from "lodash.transform";
import { v4 as uuid } from "uuid";
import axios from "axios";

import Campaign from "models/campaign";
import Email, { EmailAttributes } from "models/email";
import LandingPage, { LandingPageAttributes } from "models/landing-page";
import User from "models/user";
import { Client } from "models";
import { TouchpointVersionAttributes } from "models/touchpoint-version";

import { Route as AppRoute } from "utilities/app-routes";

import {
  BuilderContent,
  ContentStatus,
  CreativeContext,
  LabelValuePair,
  TeamMember,
  ClientReviewStatus,
} from "types";
import { isEmail, isLandingPage } from "types/typeguards";
import { TouchpointType, TouchpointTypeLabel } from "types/touchpoint";
import { PaginatedRequestOptions } from "types/pagination";
import { Permission } from "types/auth";
import { CheckboxOption } from "components/forms/checkbox/checkbox";
import { InboxFilter } from "types/inbox";

// emojiUnicode isn't configured for import
const emojiUnicode = require("emoji-unicode");

export function capitalize(str: string) {
  if (str.length <= 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function createCheckboxArray(obj: { [key: string]: string }): CheckboxOption[] {
  const checkboxArray = Object.entries(obj).map(([key, label]) => {
    if (key === label) {
      label = toSpacedTitleCase(key) as string;
    }
    return {
      label: label,
      value: key,
    };
  });
  return checkboxArray;
}

export function createCreativeTypeCheckboxArray(obj: { [key: string]: string }): CheckboxOption[] {
  const checkboxArray = Object.entries(obj).map(([key, label]) => {
    if (label === TouchpointType.LANDING_PAGE) {
      label = TouchpointTypeLabel.LP_OTHER;
    }
    if (key === label) {
      label = toSpacedTitleCase(key) as string;
    }
    return {
      label: label,
      value: key,
    };
  });
  return checkboxArray;
}

// Function to check if an argument is aplhanumeric/letters and numbers
export function inputIsAlphanumeric(inputText: string) {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return !!inputText.match(letterNumber);
}

export function inputIsLowercase(inputText: string) {
  const lowercaseChecks = /^[a-z]+$/;
  return !!inputText.match(lowercaseChecks);
}

export function toCapitalCase(str: string) {
  // convert input string to capital case, remove dashes and replace with spaces
  // ex. "this-is-a-string" -> "This Is A String"
  return str
    .replace(/-/g, " ")
    .split(" ")
    .map((word, index) => {
      // if the word is in the list,
      // don't capitalize it unless it's the first word
      const lowerCaseWords = [
        "of",
        "to",
        "the",
        "and",
        "for",
        "with",
        "in",
        "at",
        "by",
        "from",
        "on",
      ];
      if (lowerCaseWords.includes(word) && index > 0) {
        return word;
      }
      const upperCaseWords = ["qa"];
      if (upperCaseWords.includes(word)) {
        return word.toUpperCase();
      }
      return capitalize(word);
    })
    .join(" ");
}

export function convertUrlToHttps(url: string) {
  // normalize the string
  const address = url.trim().toLowerCase();

  if (address === "") return; // undefined

  // Possibility 1: ["http" || "https", "www.example.com/path"]
  // Possibility 2: ["www.example.com/path"]
  const parts = address.split("://");

  // if Possibility 1
  if (parts[0].match(/^(http|https)$/)) {
    // doesn't matter if parts[0] was http or https
    // return https and everything after parts[0]
    return `https://${parts.slice(1)}`;
  }
  // if Possibility 2, just return what came in (to lowercase)
  return `https://${address}`;
}

export function toSentenceCase(str: string) {
  // convert input string to sentence case, remove dashes and replace with spaces
  // ex. "this-is-a-string" -> "This is a string"
  return capitalize(str.replace(/-/g, " "));
}

export function transformStringNullToValueNull(str: string | undefined | null) {
  if (str === "null" || str === "Null" || str === undefined) {
    return null;
  } else {
    return str;
  }
}

export const replaceEndingToUrl = (currentUrl: string, newEnding: string) => {
  const sanitizedNewEnding = newEnding.startsWith("/") ? newEnding.slice(1) : newEnding;

  const splitUrl = currentUrl.split("/");
  splitUrl.pop();
  splitUrl.push(sanitizedNewEnding);

  return splitUrl.join("/");
};

export function toTitleCase(str: string | null | undefined) {
  if (!str) {
    return;
  }
  if (str.split(" ").length > 1) {
    let capArr = str.split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capArr.join(" ");
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toSpacedTitleCase(str: string | undefined | null) {
  if (!str) {
    return;
  }
  let newString = str.toLowerCase().split("_");
  if (newString.length > 1) {
    for (var i = 0; i < newString.length; i++) {
      newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
    }
    return newString.join(" ");
  }
  return toTitleCase(str);
}

export function toCamelCase(str: string) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function toUpperCaseSpacesToUnderScores(str: string | null | undefined) {
  if (str === "null" || str === "Null") {
    return null;
  } else {
    return str?.toUpperCase().replace("-", "").replace("  ", " ").split(" ").join("_");
  }
}

export function toLowerCaseNoSpaces(str: string) {
  return str.split(" ").join("").toLowerCase();
}

export function addHyphenToAudienceSelection(str: string | undefined) {
  if (!str) {
    return;
  }
  switch (str) {
    case "Transfer Inquiries":
    case "Transfer New Names":
    case "International Existing Inquiries":
    case "International New Names":
      //replace method only acts on the first instance of the first argument
      return str.replace(" ", " - ");
    default:
      return str;
  }
}

export const removeDuplicateTeamMembers = (teamArray: TeamMember[]) => {
  let allUsers: TeamMember[] = [];
  let duplicateMemberIds: string[] = [];
  teamArray.forEach((teammember: TeamMember) => {
    if (!duplicateMemberIds.includes(teammember.id)) {
      allUsers.push(teammember);
      duplicateMemberIds.push(teammember.id);
    }
  });
  return allUsers;
};

export function formatAudienceValueToMatchApi(str: string) {
  let newString: string | string[];
  if (str.includes("-")) {
    newString = str.toLowerCase().replace("- ", "").split(" ");
  } else if (str.includes("_")) {
    newString = str.toLowerCase().split("_");
  } else {
    newString = str.toLowerCase().split(" ");
  }
  if (newString.length > 1) {
    for (let i = 1; i < newString.length; i++) {
      newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
    }
    return newString.join("");
  }
  return str.toLowerCase();
}

export function lightenDarkenColor(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2),
      )
  );
}

export function hexToRGBA(hex: string, alpha?: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function deeplyTransformEmptyStringToUndefined(object: any): any {
  if (Array.isArray(object)) {
    return object.filter((val) => !!val);
  }
  return transform(
    object,
    function (memo, value, key) {
      // prettier-ignore
      memo[key] =
        value === Object(value) ? deeplyTransformEmptyStringToUndefined(value) :
          value === "" ? undefined :
            value;
    },
    {} as any,
  );
}

export const transformEmptyObjectToNull = (obj: any) => {
  let newThemeAttributes = obj;
  for (const value in obj) {
    if (obj[value] && Object.keys(obj[value]).length === 0) {
      newThemeAttributes = { ...newThemeAttributes, [value]: null };
    }
  }
  return newThemeAttributes;
};

export const getTouchpointType = (touchpoint: TouchpointVersionAttributes): TouchpointType => {
  // Themes don't have a type attribute. Sometimes we need it.
  return touchpoint.type ?? TouchpointType.THEME;
};

export const getDisplayTouchpointType = (type: TouchpointType): string => {
  switch (type) {
    case TouchpointType.THEME:
      return "Theme";
    case TouchpointType.EMAIL:
      return "Email";
    case TouchpointType.LANDING_PAGE:
      return TouchpointTypeLabel.LP_TOUCHPOINT;
  }
};

export const getTouchpointWorkflowStatusInOrder = (statuses: ClientReviewStatus[]) => {
  const convertStatusToOrder = (status: ClientReviewStatus) => {
    switch (status) {
      case ClientReviewStatus.BACKLOG:
        return 1;
      case ClientReviewStatus.DRAFT:
        return 2;
      case ClientReviewStatus.INTERNAL_REVIEW:
        return 3;
      case ClientReviewStatus.READY_FOR_PROOFER:
        return 4;
      case ClientReviewStatus.PROOFING:
        return 5;
      case ClientReviewStatus.READY_FOR_CLIENT:
        return 6;
      case ClientReviewStatus.CLIENT_REVIEW:
        return 7;
      case ClientReviewStatus.READY_FOR_QA:
        return 8;
      case ClientReviewStatus.QA:
        return 9;
      case ClientReviewStatus.APPROVED:
        return 10;
      case ClientReviewStatus.PUBLISHED:
        return 11;
      default:
        return 100;
    }
  };

  return statuses.sort((a, b) => {
    return convertStatusToOrder(a) - convertStatusToOrder(b);
  });
};

export const getDisplayStatus = ({
  status,
  liveText,
  archivedText,
  deletedText,
  draftText,
  archivedDraftText,
  createdText,
  rejectedText,
  systemDraftText,
  isWorkflowStatus = false,
  isClientUser = false,
}: {
  status?: string | null;
  liveText?: string;
  archivedText?: string;
  deletedText?: string;
  draftText?: string;
  archivedDraftText?: string;
  createdText?: string;
  rejectedText?: string;
  systemDraftText?: string;
  isWorkflowStatus?: boolean;
  isClientUser?: boolean;
}) => {
  //this if statement takes care of overloading the case of .PUBLISHED
  if (isWorkflowStatus && status === ClientReviewStatus.PUBLISHED) {
    return "Published";
  }

  if (isClientUser) {
    switch (status) {
      case ClientReviewStatus.CLIENT_REVIEW:
        return "To Review";
      case ContentStatus.PUBLISHED:
        return "Published";
      default:
        return "In Progress";
    }
  }
  switch (status) {
    case ContentStatus.ARCHIVED:
      return archivedText || "Archived";
    case ContentStatus.ARCHIVED_DRAFT:
      return archivedDraftText || "Archived Draft";
    case ContentStatus.CREATED:
      return createdText || "Created";
    case ContentStatus.DELETED:
      return deletedText || "Deleted";
    case ContentStatus.DRAFT:
      return draftText || "Draft";
    case ContentStatus.PUBLISHED:
      return liveText || "Published";
    case ContentStatus.REJECTED:
      return rejectedText || "Rejected";
    case ContentStatus.SYSTEM_DRAFT:
      return systemDraftText || "System Draft";
    case ClientReviewStatus.BACKLOG:
      return "Backlog";
    case ClientReviewStatus.DRAFT:
      return "Draft";
    case ClientReviewStatus.INTERNAL_REVIEW:
      return "Internal review";
    case ClientReviewStatus.READY_FOR_PROOFER:
      return "Ready for proofer";
    case ClientReviewStatus.PROOFING:
      return "Proofing";
    case ClientReviewStatus.READY_FOR_QA:
      return "Ready for QA";
    case ClientReviewStatus.READY_FOR_CLIENT:
      return "Ready for client";
    case ClientReviewStatus.CLIENT_REVIEW:
      return "Client review";
    case ClientReviewStatus.QA:
      return "QA";
    case ClientReviewStatus.APPROVED:
      return "Approved";
    default:
      return "Unknown status";
  }
};

export const getDisplayCreativeContext = (topic: CreativeContext) => {
  switch (topic) {
    case CreativeContext.ACADEMICS:
      return "Academics";
    case CreativeContext.APPLY:
      return "Apply";
    case CreativeContext.CAMPUS_LIFE:
      return "Campus life";
    case CreativeContext.EDUCATION_OUTCOMES:
      return "Education outcomes";
    case CreativeContext.FINANCIAL_AID:
      return "Financial aid";
    case CreativeContext.VISIT:
      return "Visit";
    case CreativeContext.DEPOSIT:
      return "Deposit";
    case CreativeContext.NA:
      return "N/A";
    default:
      return "Unknown topic";
  }
};

export const getContentStatus = (status?: string) => {
  switch (status) {
    case "Archived":
      return ContentStatus.ARCHIVED;
    case "Published":
      return ContentStatus.PUBLISHED;
    case "Draft":
      return ContentStatus.DRAFT;
    case "Archived Draft":
      return ContentStatus.ARCHIVED_DRAFT;
    case "Created":
      return ContentStatus.CREATED;
    default:
      return ContentStatus.DRAFT;
  }
};

export const getTouchpointLockStatus = (userId: string, touchpoint: Email | LandingPage) => {
  return userId !== touchpoint.lockedBy?.id;
};

// Used to set number of columns in the Chakra UI <Grid> component
// <Grid templateColumns={{ sm: gridCols(1), md: gridCols(2), lg: gridCols(3), xl: gridCols(4) }} />
export const gridCols = (count: number) => `repeat(${count}, minmax(0, 1fr))`;

// Converts a string to dashcase. Example: dashCase("This is my string") === "this-is-my-string"
// Used for setting ids, testids, and other cases where spaces aren't desired
export const dashCase = (string: string) => string.toLowerCase().replace(/ /g, "-");

//returns all lowercase
export const undoDashCase = (string: string | undefined) =>
  string?.replace(/-/g, " ").toLowerCase();

export function displaySavedStatus(date?: string | Date, interval = 5) {
  if (!date) {
    return "Last saved time unknown";
  }

  const currentDateTime = new Date().getTime();
  const savedDateTime = new Date(date).getTime();

  const dateTimeDifference = currentDateTime - savedDateTime;

  const message = ({ label, count }: { label: string; count: number }) => {
    const unitsAgo = parseInt((dateTimeDifference / count).toFixed());
    const time = label === "second" ? roundNearest(unitsAgo, interval) : unitsAgo;
    const unit = label + (time > 1 ? "s" : "");

    if (dateTimeDifference < 2000) {
      return `Draft saved`;
    }
    return `Saved ${time} ${unit} ago`;
  };

  const second = { label: "second", count: 1000 };
  const minute = { label: "minute", count: second.count * 60 };
  const hour = { label: "hour", count: minute.count * 60 };
  const day = { label: "day", count: hour.count * 24 };

  switch (true) {
    case dateTimeDifference > day.count:
      return message(day);
    case dateTimeDifference > hour.count:
      return message(hour);
    case dateTimeDifference > minute.count:
      return message(minute);
    case dateTimeDifference > second.count:
      return message(second);
    default:
      return `Draft saved`;
  }
}

export function generateTouchpointFromAttributes(
  attributes: EmailAttributes | LandingPageAttributes,
) {
  if (isEmail(attributes)) {
    return new Email(attributes);
  }
  if (isLandingPage(attributes)) {
    return new LandingPage(attributes);
  }
  throw new Error("Unknown touchpoint type. Touchpoint not created.");
}

/**
 * Compares an email form state to a full email to see if they are equivalent
 *
 * @param email1 A partial set of email attributes making up an email builder form
 * @param email2 A complete email
 * */
export function isEqualEmailFormPartial(email1: Partial<Email>, email2: Email): boolean {
  if (
    !isEqualStringNull(email1.advancedBodySnippet, email2.advancedBodySnippet) ||
    !isEqualStringNull(email1.advancedHeadSnippet, email2.advancedHeadSnippet) ||
    !isEqualStringNull(email1.fromName, email2.fromName) ||
    !isEqualStringNull(email1.preheader, email2.preheader) ||
    !isEqualStringNull(email1.senderEmail, email2.senderEmail) ||
    !isEqualStringNull(email1.replyToEmail, email2.replyToEmail) ||
    !isEqualStringNull(email1.subjectLine, email2.subjectLine) ||
    !isEqualStringNull(
      sanitizeEmojis(JSON.stringify(email1.data?.jsonOutput)),
      sanitizeEmojis(JSON.stringify(email2.data?.jsonOutput)),
    ) ||
    !isEqualStringNull(email1.iterableEmailTypeId, email2.iterableEmailTypeId) ||
    !isEqualStringNull(email1.themeId, email2.themeId) ||
    !isEqualStringNull(email1.linkParams, email2.linkParams)
  ) {
    return false;
  }
  return true;
}

/**
 * Compares an landing page form state to a full landing page to see if they are equivalent
 *
 * @param lp1 A partial set of landing page attributes making up a landing page builder form
 * @param lp2 A complete landing page
 * */
export function isEqualLPFormPartial(lp1: Partial<LandingPage>, lp2: LandingPage): boolean {
  if (
    !isEqualStringNull(lp1.advancedBodySnippet, lp2.advancedBodySnippet) ||
    !isEqualStringNull(lp1.advancedHeadSnippet, lp2.advancedHeadSnippet) ||
    !isEqualStringNull(lp1.pageTitle, lp2.pageTitle) ||
    !isEqualStringNull(lp1.linkParams, lp2.linkParams) ||
    !isEqualStringNull(lp1.pageDescription, lp2.pageDescription) ||
    !isEqualStringNull(lp1.url, lp2.url) ||
    !isEqualStringNull(lp1.searchVisibility, lp2.searchVisibility) ||
    !isEqualStringNull(lp1.redirectDestination, lp2.redirectDestination) ||
    !isEqualStringNull(lp1.redirectDestinationType, lp2.redirectDestinationType) ||
    !isEqualStringNull(
      sanitizeEmojis(JSON.stringify(lp1.data?.jsonOutput)),
      sanitizeEmojis(JSON.stringify(lp2.data?.jsonOutput)),
    ) ||
    !isEqualStringNull(lp1.themeId, lp2.themeId)
  ) {
    return false;
  }
  return true;
}

// Since zod doesn't always parse into a form matching the API,
// if we are comparing API data to zod data, we handle the case where they are semi-equivalent
export function isEqualStringNull(
  val1: string | boolean | null | undefined,
  val2: string | boolean | null | undefined,
) {
  return (
    val1 === val2 ||
    (val1 === "" && val2 === null) ||
    (val1 === "" && val2 === undefined) ||
    (val1 === null && val2 === "") ||
    (val1 === null && val2 === undefined) ||
    (val1 === undefined && val2 === "") ||
    (val1 === undefined && val2 === null)
  );
}

export function isEqualArray(vals1?: any[], vals2?: any[]) {
  if (
    (!vals1 && !vals2) ||
    (vals1 && vals1.length === 0 && !vals2) ||
    (!vals1 && vals2 && vals2.length === 0)
  ) {
    return true;
  }
  if (!vals1 || !vals2) {
    return false;
  }
  for (var i = 0; i < vals1.length; i++) {
    if (vals1[i] !== vals2[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Get the values selected at the mount of a multiselect in a form
 *
 * @param possibleValues All values that can be selected in a multiselect
 * @param formValues The boolean representations of multiselect state values
 */
export function getMultiSelectInitialValuesFromBoolean(
  possibleValues: LabelValuePair[],
  formValues: {
    key: string;
    value: boolean;
  }[],
): LabelValuePair[] {
  let initialValues: any[] = [];
  formValues.forEach((formValue) => {
    if (formValue.value) {
      const foundValue = possibleValues.find((option) => option.value === formValue.key);
      if (!foundValue) {
        return;
      }
      initialValues = [...initialValues, foundValue];
    }
  });
  return initialValues;
}

export function getMultiSelectInitialValuesFromString(
  possibleValues: LabelValuePair[],
  formValues: {
    key: string;
    value: string;
  }[],
): LabelValuePair[] {
  let initialValues: any[] = [];
  formValues.forEach((formValue) => {
    if (formValue.value) {
      const foundValue = possibleValues.find((option) => option.label === formValue.key);
      if (!foundValue) {
        return;
      }
      initialValues = [...initialValues, foundValue];
    }
  });
  return initialValues;
}

/**  *
 * @param error Promise rejection or Zod error
 */
export const getErrorMessage = (error: any): string => {
  if (axios.isAxiosError(error) || !error.fieldErrors?.length) {
    return error.message;
  }

  let errorMessage = "";
  if (error.fieldErrors && error.fieldErrors.length > 0) {
    error.fieldErrors.forEach((fieldError: any) => {
      errorMessage += `\n${fieldError.field}: ${fieldError.message}`;
    });
  }
  return errorMessage;
};

export const sliceUrlParameter = (url: string, depth: number) => {
  return url.split("/").slice(0, -depth).join("/");
};

// isLockedToUser
export const isLocked = (content: BuilderContent, user: User) => {
  return !!content.lockedBy && content?.lockedBy?.id !== user.id;
};

export const isEditableStatus = (status?: string) => {
  if (status) {
    const { CREATED, DRAFT, SYSTEM_DRAFT, APPROVED } = ContentStatus;
    return status === CREATED || status === DRAFT || status === SYSTEM_DRAFT || status === APPROVED;
  }
  return false;
};

export function sortByProperty(
  array: Record<string, any>[],
  sortBy: string,
  direction: "asc" | "desc" = "asc",
) {
  const sortAsc = direction === "asc";
  return array.sort((itemA, itemB) =>
    itemA[sortBy] > itemB[sortBy] ? (sortAsc ? 1 : -1) : sortAsc ? -1 : 1,
  );
}

interface SortableObject {
  name: string;
  [key: string]: any;
}
export function sortAlphabetically(values: SortableObject | SortableObject[]): [] {
  return values.sort((a: SortableObject, b: SortableObject) => {
    const nameA = a.name ? a.name.toUpperCase() : "";
    const nameB = b.name ? b.name.toUpperCase() : "";

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // if names are equal
    return 0;
  });
}

export const EMPTY_RESPONSE = { items: [], totalItems: 0, size: 0, page: 0, totalPages: 0 };

export const baseIterableUrl = "https://app.iterable.com/templates/edit?templateId=";

export const generatePaginatedQueryString = (options: PaginatedRequestOptions): string => {
  const isBaseString = (str: string) => str === "?";
  let queryString = "";

  if (options.page || options.size || options.sort || options.filter || options.search) {
    queryString = "?";
  }

  if (options.page) {
    queryString += `page=${options.page}`;
  }
  if (options.size) {
    if (!isBaseString(queryString)) {
      queryString += "&";
    }
    queryString += `size=${options.size}`;
  }
  if (options.sort) {
    if (!isBaseString(queryString)) {
      queryString += "&";
    }
    queryString += `sort=${options.sort}`;
  }
  if (options.filter) {
    if (!isBaseString(queryString)) {
      queryString += "&";
    }
    queryString += `${options.filter}`;
  }
  if (options.search) {
    if (!isBaseString(queryString)) {
      queryString += "&";
    }
    queryString += `q=${options.search}`;
  }

  return queryString;
};

export const generateFilterQueryString = (filters: InboxFilter) => {
  if (!!Object.entries(filters).length) {
    let filtersQuery = "";

    Object.entries(filters).forEach(([key, value]) => {
      if (typeof value === "object" && !!value.length) {
        filtersQuery += `&${key}=${value.join()}`;
      } else if (typeof value === "boolean" || (typeof value === "string" && !!value)) {
        filtersQuery += `&${key}=${value}`;
      }
      // eslint-disable-next-line
      return;
    });

    return filtersQuery;
  }
};

export const dateToLocalTimestamp = (dateArg: Date) => {
  const date = new Date(dateArg);
  let timestamp = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  }); // 'XX:XX AM/PM'
  return timestamp;
};

export const convertTouchpointToUrl = (
  client: Client,
  campaign: Campaign,
  route: string | undefined,
) => {
  return `${client.apolloDomainUrl}/${campaign.cdnSubdirectoryName}/${route}`;
};

export const getAllPermissions = (): Permission[] => {
  return Object.values(Permission);
};

export type Module = {
  type: string;
  descriptor: {
    id: string;
    [key: string]: any;
  };
};

export type Column = {
  modules: Module[];
  [key: string]: any;
};

export type Row = {
  columns: Column[];
  [key: string]: any;
};

export type BuilderJsonObject = {
  page: { rows: Row[]; [key: string]: any };
  [key: string]: any;
};

export const addAnnotationIdToElements = (jsonFile: string) => {
  const jsonObj: BuilderJsonObject = JSON.parse(jsonFile);
  const moduleIds: string[] = [];

  const iterateThroughAllColumnsInAllRows = (row: Row) => {
    row.columns = row.columns.map((column: Column) => {
      column.modules = iterateAndUpdateModules(column.modules);
      return column;
    });

    return row;
  };

  const iterateAndUpdateModules = (moduleArray: Module[]) => {
    moduleArray = moduleArray.map((module: any) => {
      return addAnnotationIdToModule(module);
    });
    return moduleArray;
  };

  const addAnnotationIdToModule = (module: Module) => {
    if (!module.descriptor.id || moduleIds.includes(module.descriptor.id)) {
      module.descriptor.id = uuid();
    }
    moduleIds.push(module.descriptor.id);
    return module;
  };

  if (jsonObj.page.rows) {
    jsonObj.page.rows = jsonObj.page.rows.map((row: Row) => {
      return iterateThroughAllColumnsInAllRows(row);
    });
    return JSON.stringify(jsonObj);
  }

  return jsonFile;
};

export const findModules = (jsonObj?: BuilderJsonObject) => {
  let modules: any[] = [];

  if (jsonObj?.page.rows) {
    jsonObj.page.rows.forEach((row: Row) => {
      row.columns.forEach((column: Column) => {
        modules.push(...column.modules);
      });
    });
  }
  return modules;
};

export const isCreativeObjectPage = (currentLocation: string): boolean => {
  const currentPath = currentLocation.split("/");
  const specificRoute = [AppRoute.details, AppRoute.versions, AppRoute.preview] as string[];

  return specificRoute.includes("/" + currentPath[currentPath.length - 1]);
};

/**
 * Round a number to the nearest round
 *
 * @param num The number to be rounded
 * @param round The nearest number to round to, i.e if round is 5, num will round to nearest 5
 */
export const roundNearest = (num: number, round: number) => {
  return Math.round(num / round) * round;
};

const iterateThroughAllColumnsInAllRows = (row: Row): Row => {
  const newColumns: Column[] = row.columns.map((column) => {
    return { ...column, modules: iterateAndUpdateModules(column.modules) };
  });
  return { ...row, columns: newColumns };
};

const iterateAndUpdateModules = (moduleArray: Module[]): Module[] => {
  const newModules: Module[] = moduleArray.map((module) => {
    return sanitizeHtmlModule(module);
  });
  return newModules;
};

const sanitizeHtmlModule = (module: Module): Module => {
  let moduleType = null;
  let moduleContent = null;

  if (module.type.includes("modules-html")) {
    moduleType = "html";
    moduleContent = "html";
  } else if (module.type.includes("modules-video")) {
    moduleType = "video";
    moduleContent = "src";
  } else if (module.type.includes("modules-heading")) {
    moduleType = "heading";
    moduleContent = "text";
  } else if (module.type.includes("modules-text")) {
    moduleType = "text";
    moduleContent = "html";
  } else if (module.type.includes("modules-button")) {
    moduleType = "button";
    moduleContent = "label";
  } else if (module.type.includes("modules-menu")) {
    moduleType = "menuItemsList";
    moduleContent = "items";
  } else {
    return module;
  }

  if (moduleType === "menuItemsList") {
    module.descriptor.menuItemsList.items = module.descriptor.menuItemsList.items.map(
      (item: any) => {
        item.text = sanitizeEmojis(item.text);
        item.link.title = sanitizeEmojis(item.link.title);
        return item;
      },
    );
  } else {
    module.descriptor[moduleType][moduleContent] = sanitizeEmojis(
      module.descriptor[moduleType][moduleContent],
    );
  }

  return {
    ...module,
    descriptor: { ...module.descriptor, id: module.descriptor.id },
  };
};

/**
 * Sanitize special characters
 * @param json The object to be sanitized
 */
export const sanitizeBuilderJson = (json: string | BuilderJsonObject): string => {
  const jsonObj: BuilderJsonObject = typeof json === "string" ? JSON.parse(json) : json;
  const newRows: Row[] = jsonObj.page.rows.map((row: Row) => {
    return iterateThroughAllColumnsInAllRows(row);
  });
  return JSON.stringify({ ...jsonObj, page: { ...jsonObj.page, rows: newRows } });
};

/**
 * Sanitize special characters
 * @param json The object to be sanitized
 */
export const sanitizeBuilderHtml = (htmlString: string): string => {
  //get body substring to replace special characters
  const body = htmlString.substring(htmlString.indexOf("<body"), htmlString.indexOf("</body>"));
  const sanitizedBody = sanitizeEmojis(body);

  return htmlString.replace(body, sanitizedBody);
};

/**
 * Sanitize Emojis
 * Replace unicode emoji characters with hexadecimal characters
 * @param htmlString String of text to be sanitized
 */
export function sanitizeEmojis(htmlString: any) {
  const emojiReg = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;

  if (!emojiReg.test(htmlString)) {
    return htmlString;
  }

  let arr: any = [];
  const map: any = [];

  //map any emojis in the string to use as reference when replacing
  while ((arr = emojiReg.exec(htmlString)) !== null) {
    map.push(emojiUnicode(arr[0]));
  }

  //format emoji to be hexadecimal
  const formatEmoji = (e: string) => {
    return `&#x${emojiUnicode(e)};`;
  };

  //replace emojis
  const stringArray = htmlString.split(" ");
  const convertedString = stringArray
    .map((s: any) => {
      return s.replace(emojiReg, (match: string) => formatEmoji(match));
    })
    .join(" ");

  return convertedString;
}

/**
 *
 * @param date JS Date object
 * @param unit The unit of time to measure by. Uses milliseconds by default.
 * @returns The amount of time between now and date param
 */
export function getTimeBetweenDates(
  startDate: Date | string,
  endDate: Date | string,
  unit?: "milliseconds" | "seconds" | "minutes" | "hours" | "days",
): number {
  const count = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
  };
  const start = typeof startDate === "string" ? new Date(startDate) : startDate;
  const end = typeof endDate === "string" ? new Date(endDate) : endDate;

  const startTime = Math.max(start.getTime(), end.getTime());
  const endTime = Math.min(start.getTime(), end.getTime());

  const dateTimeDifference = startTime - endTime;

  return Math.round(dateTimeDifference / count[unit || "milliseconds"]);
}

export function getTimeSince(
  endDate: Date | string,
  unit?: "milliseconds" | "seconds" | "minutes" | "hours" | "days",
): number {
  const now = new Date();
  return getTimeBetweenDates(now, endDate, unit);
}

export function isValidUrl(urlString: string): boolean {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
}
