export interface NamedDictionaryItem {
  name: string;
  description: string;
}

export interface TouchpointStatusDictionaryItem {
  status: string;
  clientReviewStatusName: string;
  versionStatusName: string;
}

// TODO: Get these from the API somehow
export const DEFAULT_PROGRAM_TYPE = "AWARE_TO_APPLY";

/** Only default for AWARE_TO_APPLY */
export const DEFAULT_CAMPAIGN_OBJECTIVE = "INQUIRY_GENERATION";

/** Temporary until provided by API */
export const POPULATION_TYPES: NamedDictionaryItem[] = [
  {
    name: "PARENT",
    description: "Parent",
  },
  {
    name: "STUDENT",
    description: "Student",
  },
];

/** Temporary until provided by API */
export const AUDIENCE_TYPES: NamedDictionaryItem[] = [
  {
    name: "TRANSFER_PROSPECTS",
    description: "Transfer Prospects",
  },
  {
    name: "INTERNATIONAL_PROSPECTS",
    description: "International Prospects",
  },
  {
    name: "FIRST_YEAR_PROSPECTS",
    description: "First Year Prospects",
  },
  {
    name: "FIRST_YEAR_INQUIRIES",
    description: "First Year Inquiries",
  },
  {
    name: "FIRST_YEAR_NEW_NAMES",
    description: "First Year New Names",
  },
  {
    name: "TRANSFER_INQUIRIES",
    description: "Transfer Inquiries",
  },
  {
    name: "TRANSFER_NEW_NAMES",
    description: "Transfer New Names",
  },
  {
    name: "INTERNATIONAL_EXISTING_INQUIRIES",
    description: "International Existing Inquiries",
  },
  {
    name: "INTERNATIONAL_NEW_NAMES",
    description: "International New Names",
  },
  {
    name: "APPLICANTS",
    description: "Applicants",
  },
  {
    name: "ADMITTED_STUDENTS",
    description: "Admitted Students",
  },
  {
    name: "DEPOSITED_STUDENTS",
    description: "Deposited Students",
  },
  {
    name: "PARENTS",
    description: "Parents",
  },
];
