import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from 'state';

interface LocalStorageSlice {
  id_token: string | undefined;
}

const initialState: LocalStorageSlice = { id_token: undefined };

export const localStorageSlice = createSlice({
  name: "localStorage",
  initialState,
  reducers: {
    setIdToken: (state, action: PayloadAction<string | undefined>) => {
      state.id_token = action.payload;
    },
  },
});

export const idTokenSelector = (state: RootState) =>
  state.localStorage.id_token;

export const { setIdToken } = localStorageSlice.actions;
export default localStorageSlice.reducer;

function selectorForKey(key: keyof LocalStorageSlice) {
  return {
    id_token: idTokenSelector,
  }[key];
}

function actionForKey(key: keyof LocalStorageSlice) {
  return {
    id_token: setIdToken,
  }[key];
}

/**
 * Persists a redux value in local storage.
 */
export function useLocalStorage(
  key: keyof LocalStorageSlice,
  initialValue: string = ""
): [string, (newValue: string) => void, () => void] {
  const dispatch = useDispatch();
  const selector = selectorForKey(key);
  const action = actionForKey(key);

  const storedValue = useSelector(selector);

  useEffect(() => {
    /**
     * Gets the value for the given key in local storage or uses
     * the provided initial value.
     * Note: getItem may throw an exception if local storage
     * is disabled.
     */
    function getStorageValue() {
      try {
        const item: string | null = localStorage.getItem(key);
        const value = item || initialValue;

        return value;
      } catch (error) {
        return initialValue;
      }
    }

    if (!storedValue) {
      dispatch(actionForKey(key)(getStorageValue()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Saves changed value for the given key in local storage.
   * Note: setItem may throw an exception if local storage is full
   * or if it's disabled in certain browsers.
   */
  const setValue = useCallback(
    (newValue: string) => {
      try {
        localStorage.setItem(key, newValue);
      } catch (error) {
        throw new Error(`Error saving ${key} to local storage`);
      } finally {
        dispatch(action(newValue));
      }
    },
    [key, dispatch, action]
  );

  const clearStoredValue = useCallback(() => {
    localStorage.removeItem(key);
    dispatch(action(undefined));
  }, [key, dispatch, action]);

  return [storedValue || "", setValue, clearStoredValue];
}

export type { LocalStorageSlice };
