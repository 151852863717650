import { TeamMember, ContentStatus, ClientReviewStatus } from "types";
import { TouchpointType } from "types/touchpoint";

export type InboxRequestParams = (string | number)[];

export interface InboxItem {
  actionItems: number;
  assignee: TeamMember | null;
  campaign: { id: string; name: string };
  campaignAudiences: string[];
  campaignId?: string;
  campaignName?: string;
  clientId?: string;
  clientName?: string;
  touchpointId?: string;
  touchpointName?: string;
  touchpointType?: string;
  touchpointVersionId?: string;
  touchpointVersionStatus?: string;
  touchpointVersionVersion?: string;
  client: { id: string; name: string };
  openCommentCount: number;
  hasExpiredTheme: boolean;
  id: string;
  lastModifiedDate: string;
  name: string;
  priority: number;
  theme: {
    description: string;
    draftedFromVersion: string;
    id: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    name: string;
    rootThemeId: string;
    status: ContentStatus;
    version: string;
    versionNotes: string;
  };
  type: TouchpointType;
  workflowStatus: ClientReviewStatus;
  status?: string;
  openActionCommentCount?: boolean;
}

export interface InboxStatus {
  assignmentCount: number;
}

export type InboxTableColumnId =
  | "touchpointName"
  | "publishLabel"
  | "clientName"
  | "lastModifiedDate"
  | "openCommentCount"
  | "campaignName"
  | "touchpointVersionStatus"
  | "campaignAudiences";

export interface InboxTableColumn {
  label: string;
  id: InboxTableColumnId;
  isSortable?: boolean;
  isDisplayed?: boolean;
  width?: string;
}

export type InboxTableColumns = (InboxTableColumn | null)[];

export enum QuickFilterTypes {
  hasOpenActionItems = "has-open-action-items",
  toReview = "to-review",
}

export enum FilterTypes {
  clientReviewStatus = "client-review-status",
  creativeType = " creative-type",
  contentTypes = " content-types",
  comments = "comments",
  clients = " clients",
}
export enum ClientFilterTypes {
  campaign = "campaign",
  programType = "program-type",
  theme = "theme",
}

export interface InboxFilter {
  assigneeClientIds?: InboxRequestParams;
  campaignIds?: InboxRequestParams;
  clientIds?: InboxRequestParams;
  contentTypes?: InboxRequestParams;
  hasActionItems?: boolean | null;
  hasOpenActionComments?: boolean | null;
  hasOpenComments?: boolean | null;
  programTypes?: InboxRequestParams;
  themeIds?: InboxRequestParams;
  types?: InboxRequestParams;
  userRoles?: InboxRequestParams;
  workflowStatuses?: InboxRequestParams;
  touchpointVersionStatus?: InboxRequestParams;
  touchpointType?: string[];
  q?: string;
  clientId?: string[];
  touchpointContentType?: string[];
}
