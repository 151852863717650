import { forwardRef } from "react";
import { InputProps as ChakraInputProps, Input as ChakraInput, InputGroup } from "@chakra-ui/react";

import {
  InputControl,
  InputControlProps,
  useInputProps,
} from "components/forms/input-control/input-control";

export interface TextInputProps extends ChakraInputProps, InputControlProps {
  inputLeftElement?: React.ReactElement;
  inputRightElement?: React.ReactElement;
  isEditable?: boolean;
}

const Input = forwardRef<HTMLInputElement, TextInputProps>(
  ({ inputLeftElement, inputRightElement, ...props }, ref) => {
    const { controlProps, inputProps } = useInputProps(props);

    return (
      <InputControl {...controlProps}>
        <InputGroup>
          {inputLeftElement}
          <ChakraInput ref={ref} {...inputProps} />
          {inputRightElement}
        </InputGroup>
      </InputControl>
    );
  },
);

Input.displayName = "Input";

export default Input;
