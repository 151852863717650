import {
  CreativeNameColumn,
  LastUpdatedColumn,
  ClientReviewStatusColumn,
  CommentsColumn,
  AudienceColumn,
  ClientNameColumn,
  PublishedStateColumn,
  CampaignNameColumn,
} from "components/table/table-columns/table-columns";
import { TableRow, TableRowProps } from "components/table/table";

import { InboxItem, InboxTableColumns } from "types/inbox";
import { ContentStatus } from "types";

interface InboxTableRowProps extends TableRowProps {
  isClientUser: boolean;
  item: InboxItem;
  tableColumns: InboxTableColumns;
}

export const InboxTableRow = ({ isClientUser, item, tableColumns }: InboxTableRowProps) => {
  return (
    <TableRow>
      {tableColumns.map((column) => {
        if (!column || column.isDisplayed === false) return false;

        switch (column.id) {
          // internal view: "Creative name" column
          // client view: "Touchpoint version" column
          case "touchpointName":
            return <CreativeNameColumn key={column.id} item={item} isClientUser={isClientUser} />;

          // internal view: "Client name" column
          case "clientName":
            return <ClientNameColumn key={column.id} clientName={item.clientName} />;

          // internal view: "Last updated" column
          case "lastModifiedDate":
            return <LastUpdatedColumn key={column.id} lastModifiedDate={item.lastModifiedDate} />;

          // internal view: "Client Review Status" column
          case "touchpointVersionStatus":
            return (
              <ClientReviewStatusColumn
                key={column.id}
                status={item?.touchpointVersionStatus as ContentStatus}
              />
            );

          // internal view: "Publish state" column
          case "publishLabel":
            return (
              <PublishedStateColumn
                key={column.id}
                status={item?.touchpointVersionStatus as ContentStatus}
              />
            );

          // internal view: "Open comments" column
          case "openCommentCount":
            return <CommentsColumn key={column.id} item={item} />;

          // client view: "Audience" column
          case "campaignAudiences":
            return <AudienceColumn key={column.id} audiences={item.campaignAudiences} />;

          // client view: "Campaign name" column
          case "campaignName":
            return <CampaignNameColumn key={column.id} campaignName={item.campaignName} />;

          default:
            return null;
        }
      })}
    </TableRow>
  );
};
