import Model, { Unpersisted } from "models/model";

import { generatePaginatedQueryString } from "utilities";

import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";

interface CampaignAttributes {
  audiences?: string[];
  campaignObjective?: string;
  classes?: string[];
  clientId?: string;
  defaultThemeId?: string;
  domainUrl?: string;
  id: string;
  keyObjectives?: string;
  name: string;
  primayMetric?: string;
  status?: string;
  programType?: string;
  lastModifiedDate: string;
  cdnSubdirectoryName: string;
  populationType?: string;
}

class Campaign extends Model<CampaignAttributes> implements CampaignAttributes {
  // -------- persistence methods ---------
  static all({
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
    clientId,
    programType,
  }: {
    options?: PaginatedRequestOptions;
    clientId: string;
    programType?: string;
  }): Promise<PaginatedResponse<Campaign>> {
    let queryString = generatePaginatedQueryString(options);
    if (programType) {
      // TODO: turn &<key>=<value> into &<key>=<value>,<value>,<value>
      // TODO: make this into a utility function
      queryString += `&programType=${programType}`;
    }
    return Campaign.connection
      .get(`clients/${clientId}/campaigns${queryString}`)
      .then((response) => {
        const items = response.data.content
          ? response.data.content.map((attributes: CampaignAttributes) => new Campaign(attributes))
          : [];
        return {
          items: items,
          totalItems: response.data.totalElements,
          size: response.data.size,
          page: response.data.number,
          totalPages: response.data.totalPages,
          last: response.data.last,
        };
      });
  }

  static find(clientId: string, id: string): Promise<Campaign> {
    return this.connection.get(`clients/${clientId}/campaigns/${id}`).then((response) => {
      return new Campaign(response.data);
    });
  }

  static create(clientId: string, attributes: Unpersisted<CampaignAttributes>): Promise<Campaign> {
    return this.connection.post(`clients/${clientId}/campaigns`, attributes).then((response) => {
      return new Campaign(response.data);
    });
  }

  static update(
    clientId: string,
    id: string,
    attributes: Unpersisted<CampaignAttributes>,
  ): Promise<Campaign> {
    return this.connection
      .patch(`clients/${clientId}/campaigns/${id}`, attributes)
      .then((response) => {
        return new Campaign(response.data);
      });
  }

  static replace(clientId: string, attributes: CampaignAttributes): Promise<Campaign> {
    return this.connection
      .put(`clients/${clientId}/campaigns/${attributes.id}`, attributes)
      .then((response) => {
        return new Campaign(response.data);
      });
  }

  // --------- behavior (abstract implementations or custom) --------
  get attributes(): CampaignAttributes {
    return {
      audiences: this.audiences,
      campaignObjective: this.campaignObjective,
      classes: this.classes,
      clientId: this.clientId,
      defaultThemeId: this.defaultThemeId,
      domainUrl: this.domainUrl,
      id: this.id,
      keyObjectives: this.keyObjectives,
      name: this.name,
      primayMetric: this.primayMetric,
      status: this.status,
      programType: this.programType,
      lastModifiedDate: this.lastModifiedDate,
      cdnSubdirectoryName: this.cdnSubdirectoryName,
      populationType: this.populationType,
    };
  }

  // -------- proxies to attributes ---------
  get audiences() {
    return this._attributes["audiences"];
  }

  get campaignObjective() {
    return this._attributes["campaignObjective"];
  }

  get classes() {
    return this._attributes["classes"];
  }

  get clientId() {
    return this._attributes["clientId"];
  }

  get defaultThemeId() {
    return this._attributes["defaultThemeId"];
  }

  get domainUrl() {
    return this._attributes["domainUrl"];
  }

  get keyObjectives() {
    return this._attributes["keyObjectives"];
  }

  get primayMetric() {
    return this._attributes["primayMetric"];
  }

  get programType() {
    return this._attributes["programType"];
  }

  get name() {
    return this._attributes["name"];
  }

  get id() {
    return this._attributes["id"];
  }

  get status() {
    return this._attributes["status"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get cdnSubdirectoryName() {
    return this._attributes["cdnSubdirectoryName"];
  }

  get populationType() {
    return this._attributes["populationType"];
  }
}

export default Campaign;
export type { CampaignAttributes };
