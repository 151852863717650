import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { Route as AppRoute } from "utilities/app-routes";

import CampaignCreativeRoutes from "containers/admin/clients/campaign/campaign-creative-page/campaign-creative-routes";
import CampaignSettingsRoutes from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-routes";

import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import { Span } from "components/partials/typography/typography";
import PageContent from "components/partials/page-content/page-content";

import { useGetProgramTypesQuery, getDictionaryItemDescription } from "state/api/dictionary";
import { useCurrentCampaign } from "state/ducks/campaigns";
import { useCurrentClient } from "state/ducks/clients";
import { useCurrentUser } from "state/ducks";

import { isCreativeObjectPage } from "utilities";
import { hasPermission } from "utilities/user";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const CampaignRoutes = () => {
  let { path, url } = useRouteMatch();
  const client = useCurrentClient();
  const campaign = useCurrentCampaign();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  usePagePermission(Permission.PERM_CAMPAIGN_READ);
  const isHideSidebar = isCreativeObjectPage(useLocation().pathname);

  const tertiaryNavGroups = [
    {
      heading: "",
      links: [
        { to: url + "/creative", text: "Creative" },
        { to: url + "/settings", text: "Settings" },
      ],
    },
  ];

  return (
    <>
      {!isHideSidebar && (
        <SecondaryNav
          backButton={{
            show: !hasPermission(currentUser, Permission.PERM_CLIENT_USER),
            text: client.name,
            onClick: () => history.push(`/clients/${client.id}/campaigns`),
          }}
          heading={
            <>
              {campaign.name}
              <Span color="text.muted" ml={4}>
                {getDictionaryItemDescription(programTypes, campaign.programType)}
              </Span>
            </>
          }
        />
      )}

      <PageContent
        hideSidebar={isHideSidebar}
        tertiaryNavGroups={tertiaryNavGroups}
        data-testid="campaign-routes">
        <Switch>
          <Redirect exact from={path} to={path + AppRoute.settings} />

          <Route path={path + AppRoute.settings}>
            <CampaignSettingsRoutes />
          </Route>

          <Route path={path + AppRoute.creative}>
            <CampaignCreativeRoutes />
          </Route>
        </Switch>
      </PageContent>
    </>
  );
};

export default CampaignRoutes;
