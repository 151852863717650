import { createApi } from "@reduxjs/toolkit/query/react";

import { Model } from "models";
import {
  POPULATION_TYPES,
  AUDIENCE_TYPES,
  type TouchpointStatusDictionaryItem,
  type NamedDictionaryItem,
} from "./dictionary.types";

enum DictionaryEndpoint {
  AUDIENCE_TYPES = "audience-types",
  CAMPAIGN_CLASS_TYPES = "campaign-class-types",
  CAMPAIGN_OBJECTIVE_TYPES = "campaign-objective-types",
  POPULATION_TYPES = "population-types",
  PROGRAM_TYPES = "program-types",
  TOUCHPOINT_CONTENT_TYPES = "touchpoint-content-types",
  TOUCHPOINT_VERSION_STATUSES = "touchpoint-version-statuses",
}

const dictionaryQuery = async (args: { url: DictionaryEndpoint; params?: Record<string, any> }) => {
  try {
    const { url, params } = args;

    if (url === DictionaryEndpoint.POPULATION_TYPES) {
      return { data: POPULATION_TYPES };
    }

    if (url === DictionaryEndpoint.AUDIENCE_TYPES) {
      return { data: AUDIENCE_TYPES };
    }

    const response = await Model.connection.get(`dictionary/${url}`, { params });

    return { data: response.data };
  } catch (error: any) {
    return {
      error: {
        status: error.response?.status,
        data: error.response?.data || error.message,
      },
    };
  }
};

export const dictionaryApi = createApi({
  reducerPath: "dictionaryApi",
  baseQuery: dictionaryQuery,
  endpoints: (builder) => ({
    /**
     * @example `[{ "name": "FRESHMAN", "description": "Freshman" }]` */
    getCampaignClassTypes: builder.query<NamedDictionaryItem[], string | void>({
      query: (campaignObjective) => ({
        url: DictionaryEndpoint.CAMPAIGN_CLASS_TYPES,
        params: campaignObjective ? { campaignObjective } : undefined,
      }),
    }),
    /**
     * @example `[{ "name": "APPLICATION_GENERATION", "description": "Application Generation" }]` */
    getCampaignObjectiveTypes: builder.query<NamedDictionaryItem[], string | void>({
      query: (programType) => ({
        url: DictionaryEndpoint.CAMPAIGN_OBJECTIVE_TYPES,
        params: programType ? { programType } : undefined,
      }),
    }),
    /**
     * @example `[{ "name": "FAFSA", "description": "FAFSA" }]` */
    getTouchpointContentTypes: builder.query<NamedDictionaryItem[], string | void>({
      query: (campaignObjective) => ({
        url: DictionaryEndpoint.TOUCHPOINT_CONTENT_TYPES,
        params: campaignObjective ? { campaignObjective } : undefined,
      }),
    }),
    /**
     * @example `[{ "name": "TRANSFER_PROSPECTS", "description": "Transfer Prospects" }]` */
    getAudienceTypes: builder.query<NamedDictionaryItem[], void>({
      query: () => ({ url: DictionaryEndpoint.AUDIENCE_TYPES }),
    }),
    /**
     * @returns `[{ "name": "PARENT", "description": "Parent" }, { "name": "STUDENT", "description": "Student" }]` */
    getPopulationTypes: builder.query<NamedDictionaryItem[], void>({
      query: () => ({ url: DictionaryEndpoint.POPULATION_TYPES }),
    }),
    /**
     * @returns `[{ "name": "AWARE_TO_APPLY", "description": "Aware to Apply" }, { "name": "ADMIT_TO_ENROLL", "description": "Admit to Enroll" }]` */
    getProgramTypes: builder.query<NamedDictionaryItem[], void>({
      query: () => ({ url: DictionaryEndpoint.PROGRAM_TYPES }),
    }),
    /**
     * @example `[{ "status": "DRAFT", "clientReviewStatusName": "Draft", "versionStatusName": "Draft" }]` */
    getTouchpointVersionStatuses: builder.query<TouchpointStatusDictionaryItem[], void>({
      query: () => ({ url: DictionaryEndpoint.TOUCHPOINT_VERSION_STATUSES }),
    }),
  }),
});

export const {
  useGetAudienceTypesQuery,
  useGetCampaignClassTypesQuery,
  useGetCampaignObjectiveTypesQuery,
  useGetPopulationTypesQuery,
  useGetProgramTypesQuery,
  useGetTouchpointContentTypesQuery,
  useGetTouchpointVersionStatusesQuery,
} = dictionaryApi;
