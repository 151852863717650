import { AxiosResponse } from "axios";

import Model, { Unpersisted } from "models/model";

import { generatePaginatedQueryString } from "utilities";

import { TeamMember, ClientReviewStatus } from "types";
import { Comment, CommentsSummary } from "types/comments";
import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";
import { TouchpointStatus, TouchpointType } from "types/touchpoint";

export const DEFAULT_TOUCHPOINT_DATA = { htmlOutput: "<>", jsonOutput: undefined };

interface TouchpointAttributes {
  assigneeId?: string | null;
  assignee?: TeamMember | null;
  campaignId: string;
  clientId: string;
  contentInteraction?: string | null;
  contextCampusLife?: boolean;
  contextFinancialAid?: boolean;
  contextVisit?: boolean;
  contextEducationOutcomes?: boolean;
  contextAcademics?: boolean;
  contextApply?: boolean;
  contextDeposit?: boolean;
  contextNA?: boolean;
  contentType: string;
  description?: string;
  draftedFromVersion?: string;
  id: string;
  iterableJourneyId?: string;
  iterableNodeId?: string;
  lastModifiedBy?: string;
  lastModifiedDate: string;
  name: string;
  publishedAt?: string;
  releaseType: string;
  rootTouchpointId?: string;
  status?: string;
  type: TouchpointType;
  version?: string;
  versionNotes?: string;
  url?: string | null;
  pageDescription?: string | null;
  pageTitle?: string | null;
  priority?: number | null;
  subjectLine?: string | null;
  iterableTemplateId?: string | null;
  iterableEmailTypeId?: string | null;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTouchpoint?: string;
  workflowStatus?: ClientReviewStatus | null;
}

export function getTouchpointStatus(status: string | undefined) {
  if (status === TouchpointStatus.DESIGN) {
    return TouchpointStatus.DESIGN;
  }
  if (status === TouchpointStatus.DEVELOPMENT) {
    return TouchpointStatus.DEVELOPMENT;
  }
  if (status === TouchpointStatus.DONE) {
    return TouchpointStatus.DONE;
  }
  if (status === TouchpointStatus.NEW) {
    return TouchpointStatus.NEW;
  }
  if (status === TouchpointStatus.QA) {
    return TouchpointStatus.QA;
  }
}

class Touchpoint extends Model<TouchpointAttributes> implements TouchpointAttributes {
  // -------- persistence methods ---------
  static all({
    clientId,
    campaignId,
    type,
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
  }: {
    clientId: string;
    campaignId: string;
    type?: TouchpointType;
    options: PaginatedRequestOptions;
  }): Promise<PaginatedResponse<Touchpoint>> {
    let queryString = generatePaginatedQueryString(options);
    if (type) {
      queryString += `&type=${type}`;
    }
    return Touchpoint.connection
      .get(`clients/${clientId}/campaigns/${campaignId}/touchpoints${queryString}`)
      .then((response) => {
        const items = response.data.content
          ? response.data.content.map(
              (touchpoint: TouchpointAttributes) => new Touchpoint(touchpoint),
            )
          : [];
        return {
          items: items,
          totalItems: response.data.totalElements,
          size: response.data.size,
          page: response.data.number,
          totalPages: response.data.totalPages,
          last: response.data.last,
        };
      });
  }

  static create({
    clientId,
    campaignId,
    attributes,
  }: {
    clientId: string;
    campaignId: string;
    attributes: Unpersisted<TouchpointAttributes>;
  }): Promise<Touchpoint> {
    return this.connection
      .post(`clients/${clientId}/campaigns/${campaignId}/touchpoints`, attributes)
      .then((response) => {
        return new Touchpoint(response.data);
      });
  }

  static find({
    clientId,
    campaignId,
    id,
  }: {
    clientId: string;
    campaignId: string;
    id: string;
  }): Promise<Touchpoint> {
    return this.connection
      .get(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${id}`)
      .then((response) => {
        return new Touchpoint(response.data);
      });
  }

  static nextVersions({
    clientId,
    campaignId,
    id,
  }: {
    clientId: string;
    campaignId: string;
    id: string;
  }) {
    return this.connection
      .get(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${id}/next-versions`)
      .then((response) => {
        return response;
      });
  }

  static htmlFromJson({
    jsonFile,
    type,
  }: {
    jsonFile: any;
    type: string;
  }): Promise<AxiosResponse<any>> {
    return this.connection.post(`/bee/process-json`, { data: jsonFile, htmlContentType: type });
  }

  static replace({
    campaignId,
    clientId,
    id,
    data,
  }: {
    campaignId: string;
    clientId: string;
    id: string;
    data: Partial<TouchpointAttributes>;
  }) {
    return this.connection
      .put(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${id}`, data)
      .then((response) => {
        return new Touchpoint(response.data);
      });
  }

  static update({
    campaignId,
    clientId,
    id,
    data,
  }: {
    campaignId: string;
    clientId: string;
    id: string;
    data: Partial<TouchpointAttributes>;
  }) {
    return this.connection
      .patch(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${id}`, data)
      .then((response) => {
        return new Touchpoint(response.data);
      });
  }

  static delete({
    campaignId,
    clientId,
    id,
  }: {
    campaignId: string;
    clientId: string;
    id: string;
  }): Promise<void> {
    return this.connection
      .delete(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${id}`)
      .then();
  }

  static workflowStatusOptions({
    clientId,
    campaignId,
    touchpointId,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
  }): Promise<ClientReviewStatus[]> {
    return this.connection
      .get(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/workflow/options`,
      )
      .then((res) => {
        return res.data;
      });
  }

  // ---------------------COMMENT FUNCTIONS---------------------

  static commentsSummary({
    clientId,
    campaignId,
    touchpointId,
    hiddenFromClient,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    hiddenFromClient?: boolean;
  }): Promise<CommentsSummary> {
    let queryString = "";
    if (hiddenFromClient !== undefined) {
      queryString += `?hidden=${hiddenFromClient}`;
    }

    return this.connection
      .get(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments/summary${queryString}`,
      )
      .then((res) => {
        return res.data;
      });
  }

  static allComments({
    clientId,
    campaignId,
    touchpointId,
    hiddenFromClient,
    options = {},
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    hiddenFromClient?: boolean;
    options?: PaginatedRequestOptions;
  }) {
    let queryString = generatePaginatedQueryString(options);

    if (hiddenFromClient !== undefined) {
      queryString += `&hidden=${hiddenFromClient}`;
    }

    return this.connection
      .get(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments${queryString}`,
      )
      .then((res) => {
        return res.data;
      });
  }

  static createComment({
    campaignId,
    clientId,
    touchpointId,
    data,
  }: {
    campaignId: string;
    clientId: string;
    touchpointId: string;
    data: any;
  }): Promise<Comment> {
    return this.connection
      .post(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments`,
        data,
      )
      .then((res) => {
        return res.data;
      });
  }

  static replaceComment({
    campaignId,
    clientId,
    touchpointId,
    data,
  }: {
    campaignId: string;
    clientId: string;
    touchpointId: string;
    data: Comment;
  }): Promise<Comment> {
    return this.connection
      .put(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments/${data.id}`,
        data,
      )
      .then((res) => {
        return res.data;
      });
  }

  static createReply({
    campaignId,
    clientId,
    touchpointId,
    parentId,
    text,
  }: {
    campaignId: string;
    clientId: string;
    touchpointId: string;
    // Do we really want parentId to be optional here or just check it where it's called?
    parentId?: string;
    text: string;
  }) {
    return this.connection
      .post(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments/${parentId}/replies`,
        {
          text: text,
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static allReplies({
    clientId,
    campaignId,
    touchpointId,
    parentId,
    options = {},
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    parentId: string;
    options?: PaginatedRequestOptions;
  }) {
    const queryString = generatePaginatedQueryString(options);

    return this.connection
      .get(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/comments/${parentId}/replies${queryString}`,
      )
      .then((res) => {
        return res.data;
      });
  }

  static getEmailTypes({ clientId }: { clientId: string }) {
    return this.connection.get(`clients/${clientId}/email-types`).then((response) => {
      return response.data;
    });
  }

  static syncEmailTypes() {
    return this.connection.get(`/admin/trigger-email-type-sync`);
  }

  static setDefaultEmailType({ clientId, emailTypeId }: { clientId: string; emailTypeId: string }) {
    return this.connection
      .put(`clients/${clientId}/email-types/${emailTypeId}`, { defaultForClient: true })
      .then((response) => {
        return response.data;
      });
  }

  get attributes(): TouchpointAttributes {
    return {
      assignee: this.assignee,
      assigneeId: this.assigneeId,
      campaignId: this.campaignId,
      contentInteraction: this.contentInteraction,
      clientId: this.clientId,
      contextCampusLife: this.contextCampusLife,
      contextFinancialAid: this.contextFinancialAid,
      contextVisit: this.contextVisit,
      contextEducationOutcomes: this.contextEducationOutcomes,
      contextAcademics: this.contextAcademics,
      contextApply: this.contextApply,
      contextDeposit: this.contextDeposit,
      contextNA: this.contextNA,
      contentType: this.contentType,
      description: this.description,
      draftedFromVersion: this.draftedFromVersion,
      id: this.id,
      iterableJourneyId: this.iterableJourneyId,
      iterableNodeId: this.iterableNodeId,
      lastModifiedBy: this.lastModifiedBy,
      lastModifiedDate: this.lastModifiedDate,
      name: this.name,
      publishedAt: this.publishedAt,
      releaseType: this.releaseType,
      rootTouchpointId: this.rootTouchpointId,
      status: this.status,
      type: this.type,
      url: this.url,
      pageDescription: this.pageDescription,
      pageTitle: this.pageTitle,
      priority: this.priority,
      subjectLine: this.subjectLine,
      iterableTemplateId: this.iterableTemplateId,
      iterableEmailTypeId: this.iterableEmailTypeId,
      utmCampaign: this.utmCampaign,
      utmMedium: this.utmMedium,
      utmSource: this.utmSource,
      utmTouchpoint: this.utmTouchpoint,
      workflowStatus: this.workflowStatus,
    };
  }

  get assignee() {
    return this._attributes["assignee"];
  }

  get assigneeId() {
    return this._attributes["assigneeId"];
  }

  get campaignId() {
    return this._attributes["campaignId"];
  }

  get clientId() {
    return this._attributes["clientId"];
  }

  get contentInteraction() {
    return this._attributes["contentInteraction"];
  }

  get contextCampusLife() {
    return this._attributes["contextCampusLife"];
  }

  get contextFinancialAid() {
    return this._attributes["contextFinancialAid"];
  }

  get contextVisit() {
    return this._attributes["contextVisit"];
  }

  get contextEducationOutcomes() {
    return this._attributes["contextEducationOutcomes"];
  }

  get contextAcademics() {
    return this._attributes["contextAcademics"];
  }

  get contextApply() {
    return this._attributes["contextApply"];
  }

  get contextDeposit() {
    return this._attributes["contextDeposit"];
  }

  get contextNA() {
    return this._attributes["contextNA"];
  }

  get contentType() {
    return this._attributes["contentType"];
  }

  get description() {
    return this._attributes["description"];
  }

  get draftedFromVersion() {
    return this._attributes["draftedFromVersion"];
  }

  get id() {
    return this._attributes["id"];
  }

  get iterableEmailTypeId() {
    return this._attributes["iterableEmailTypeId"];
  }

  get iterableJourneyId() {
    return this._attributes["iterableJourneyId"];
  }

  get iterableNodeId() {
    return this._attributes["iterableNodeId"];
  }

  get iterableTemplateId() {
    return this._attributes["iterableTemplateId"];
  }

  get lastModifiedBy() {
    return this._attributes["lastModifiedBy"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get name() {
    return this._attributes["name"];
  }

  get pageDescription() {
    return this._attributes["pageDescription"];
  }

  get pageTitle() {
    return this._attributes["pageTitle"];
  }

  get priority() {
    return this._attributes["priority"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get releaseType() {
    return this._attributes["releaseType"];
  }

  get rootTouchpointId() {
    return this._attributes["rootTouchpointId"];
  }

  get status() {
    return this._attributes["status"];
  }

  get subjectLine() {
    return this._attributes["subjectLine"];
  }

  get type() {
    return this._attributes["type"];
  }

  get url() {
    return this._attributes["url"];
  }

  get utmCampaign() {
    return this._attributes["utmCampaign"];
  }

  get utmMedium() {
    return this._attributes["utmMedium"];
  }

  get utmSource() {
    return this._attributes["utmSource"];
  }

  get utmTouchpoint() {
    return this._attributes["utmTouchpoint"];
  }

  get workflowStatus() {
    return this._attributes["workflowStatus"];
  }
}

// Now labeled "Contexts" per client request but used to be Content topic to match BE
export const creativeContextOptions = [
  { value: "contextCampusLife", label: "Campus Life" },
  { value: "contextFinancialAid", label: "Financial Aid" },
  { value: "contextVisit", label: "Visit" },
  { value: "contextEducationOutcomes", label: "Education Outcomes" },
  { value: "contextAcademics", label: "Academics" },
  { value: "contextApply", label: "Apply" },
  { value: "contextDeposit", label: "Deposit" },
  { value: "contextNA", label: "N/A" },
];

export default Touchpoint;
export type { TouchpointAttributes };
