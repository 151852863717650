import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CampaignForm } from "containers/admin/clients/campaign/components/campaign-form/campaign-form";
import toast from "components/partials/toast/toast";
import { useCurrentClient } from "state/ducks/clients";
import { setCurrentCampaign, useCurrentCampaign } from "state/ducks/campaigns";
import Campaign, { CampaignAttributes } from "models/campaign";
import { getErrorMessage } from "utilities";
import { usePagePermission } from "hooks/use-page-permission";
import { Permission } from "types/auth";

const CampaignSettingsEditPage = () => {
  const client = useCurrentClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const campaign = useCurrentCampaign();

  usePagePermission(Permission.PERM_CAMPAIGN_WRITE);

  const updateCampaign = (attributes: CampaignAttributes) => {
    Campaign.replace(client.id, attributes)
      .then(async (_response) => {
        const campaignAfterUpdating = await Campaign.find(client.id, attributes.id);
        dispatch(setCurrentCampaign(campaignAfterUpdating.attributes));
        toast.success({
          data: {
            title: "Campaign updated",
          },
        });
        history.push({
          pathname: `/clients/${client.id}/campaigns/${campaignAfterUpdating.id}/settings`,
        });
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to edit Campaign",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const cancelEditCampaign = () => {
    history.push(`/clients/${client.id}/campaigns/${campaign.id}`);
  };

  return (
    <CampaignForm campaign={campaign} onSubmit={updateCampaign} onCancel={cancelEditCampaign} />
  );
};

export default CampaignSettingsEditPage;
