import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore, type Reducer } from "@reduxjs/toolkit";

import { dictionaryApi } from "state/api/dictionary";
import clientsReducer, { ClientsSlice } from "state/ducks/clients";
import touchpointsReducer, { TouchpointsSlice } from "state/ducks/touchpoint";
import localStorageReducer, { LocalStorageSlice } from "state/ducks/local-storage";
import campaignsReducer, { CampaignsSlice } from "state/ducks/campaigns";
import themesReducer, { ThemesSlice } from "state/ducks/themes";
import usersReducer, { UsersSlice } from "state/ducks/users";
import builderContentReducer, { BuilderContentSlice } from "state/ducks/builder-content";
import inboxFiltersReducer, { InboxFiltersSlice } from "state/ducks/inbox-filters";

export interface RootState {
  clients: ClientsSlice;
  localStorage: LocalStorageSlice;
  users: UsersSlice;
  campaigns: CampaignsSlice;
  themes: ThemesSlice;
  touchpoints: TouchpointsSlice;
  builderContent: BuilderContentSlice;
  inboxFilters: InboxFiltersSlice;
  [dictionaryApi.reducerPath]: ReturnType<typeof dictionaryApi.reducer>;
}

const rootReducer = combineReducers({
  clients: clientsReducer,
  localStorage: localStorageReducer,
  users: usersReducer,
  campaigns: campaignsReducer,
  themes: themesReducer,
  touchpoints: touchpointsReducer,
  builderContent: builderContentReducer,
  inboxFilters: inboxFiltersReducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
}) as Reducer<RootState>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dictionaryApi.middleware),
});

export type AppDispatch = typeof store.dispatch;

/**
  Typed version of useDispatch
  - See: https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
  Typed version of useSelector
  - See: https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { rootReducer };
export default store;
