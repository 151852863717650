import { type NamedDictionaryItem } from "./dictionary.types";

/**
 * Gets the dictionary item's description based on its name */
export const getDictionaryItemDescription = (
  items: { name: string; description: string }[] | undefined,
  itemName: string | undefined,
): string => {
  if (!items || !itemName) return "";
  return items.find((item) => item.name === itemName)?.description || "";
};

/**
 * Gets the dictionary item's name based on its description */
export const getDictionaryItemName = (
  items: { name: string; description: string }[] | undefined,
  itemDescription: string | undefined,
): string => {
  if (!items || !itemDescription) return "";
  return items.find((item) => item.description === itemDescription)?.name || "";
};

/**
 * Converts NamedDictionaryItem arrays into select options
 */
export const mapDictionaryItemsToOptions = (items: NamedDictionaryItem[] | undefined) => {
  if (!items) return [];

  return items.map((type) => (
    <option key={type.name} value={type.name}>
      {type.description}
    </option>
  ));
};

/**
 * Converts NamedDictionaryItem arrays into MultiSelect options */
export const mapDictionaryItemsToMultiSelectOptions = (
  items: NamedDictionaryItem[] | undefined,
) => {
  if (!items) return [];

  return items.map((type) => ({
    value: type.name,
    label: type.description,
  }));
};
